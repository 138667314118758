import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import {
	Button,
	Grid,
	useMediaQuery,
	SwipeableDrawer,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	IconButton,
	Typography,
	Box,
	InputBase,
	Container,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Widgets } from "@material-ui/icons";

// Custom Components
import NavigationBar from "../../components/NavigationBar";
import RecentArticleSwiper from "../../components/NewsletterPage/RecentArticleSwiper";
import OlderArticleSwiper from "../../components/NewsletterPage/OlderArticleSwiper";

import imgPokemon from "../../assets/pokemon.gif";

const NewsLetterPage = () => {

	const style = useStyles();
	const mdDown = useMediaQuery("(max-width:980px)");

	const [email, setEmail] = useState("");

	const HandleClick = () => {
		console.log(email);
	}

	return (
		<div className={style.newsLetterBody}>
			<NavigationBar />

			{/* <div className={style.container}>
				<div className={style.letterContainer}>
					<div className={style.letterPicContainer}>
						<img src={imgPokemon} width="100%"></img>
					</div>
					<div className={style.letterContentContainer}>
						<p className={style.letterTitle}>Am I not good enough?</p>
						<p className={style.letterContent}>In our beloved university of stress and tension, the phrase “low self-esteem” is too often thrown around mindlessly as some self-deprecating humor to lighten the mood.
						</p>
						<div className={style.letterInfo}>
							<p className={style.letterDate}><i>30 April 2022</i></p>
							<p><i>。</i></p>
							<p className={style.letterAuthor}><i>By Nicole</i></p>
						</div>

					</div>
				</div>

				<div className={style.letterContainer}>
					<div className={style.letterPicContainer}>
						<img src={imgPokemon} width="100%"></img>
					</div>
					<div className={style.letterContentContainer}>
						<p className={style.letterTitle}>Am I not good enough?</p>
						<p className={style.letterContent}>In our beloved university of stress and tension, the phrase “low self-esteem” is too often thrown around mindlessly as some self-deprecating humor to lighten the mood.
						</p>
						<div className={style.letterInfo}>
							<p className={style.letterDate}><i>30 April 2022</i></p>
							<p><i>。</i></p>
							<p className={style.letterAuthor}><i>By Nicole</i></p>
						</div>

					</div>
				</div>

				<div className={style.letterContainer}>
					<div className={style.letterPicContainer}>
						<img src={imgPokemon} width="100%"></img>
					</div>
					<div className={style.letterContentContainer}>
						<p className={style.letterTitle}>Am I not good enough?</p>
						<p className={style.letterContent}>In our beloved university of stress and tension, the phrase “low self-esteem” is too often thrown around mindlessly as some self-deprecating humor to lighten the mood.
						</p>
						<div className={style.letterInfo}>
							<p className={style.letterDate}><i>30 April 2022</i></p>
							<p><i>。</i></p>
							<p className={style.letterAuthor}><i>By Nicole</i></p>
						</div>

					</div>
				</div>

				<div className={style.letterContainer}>
					<div className={style.letterPicContainer}>
						<img src={imgPokemon} width="100%"></img>
					</div>
					<div className={style.letterContentContainer}>
						<p className={style.letterTitle}>Am I not good enough?</p>
						<p className={style.letterContent}>In our beloved university of stress and tension, the phrase “low self-esteem” is too often thrown around mindlessly as some self-deprecating humor to lighten the mood.
						</p>
						<div className={style.letterInfo}>
							<p className={style.letterDate}><i>30 April 2022</i></p>
							<p><i>。</i></p>
							<p className={style.letterAuthor}><i>By Nicole</i></p>
						</div>

					</div>
				</div>
			</div> */}

			<Container maxWidth="lg" style={{width: mdDown ? '100%' : '80%' }}>
				<Box
					style={{
						width: "100%",
						// border: "2px solid red",
						display: "flex",
						justifyContent: "center",
						margin: '20px 0px',
					}}
				>
					<Typography
						style={{
							fontSize: "40px",
							fontWeight: "900",
						}}
					>
						The Newsletter
					</Typography>
				</Box>

				<Box
					style={{
						display: "flex",
						width: "100%",
						margin: "auto",
						backgroundColor: "#EAEAEA",
						borderRadius: "20px",
					}}
				>
					<InputBase
						autoComplete='email'
						autoFocus
						inputProps={{ autoCapitalize: "none" }}
						placeholder="Your full email address"
						onChange={(e) => setEmail(e.target.value)}
						style={{
							backgroundColor: "#EAEAEA",
							padding: "14px 20px",
							borderRadius: "20px",
							flex: "1",
						}}
					/>
					<Button
						onClick={HandleClick}
						style={{
							backgroundColor: "#83D9DC",
							borderRadius: "20px",
							padding: "5px 35px",
							// minWidth: "120px",
							// minHeight: "24px",
							"&:hover": {
								backgroundColor: "#73c0c3",
							},
						}}
					>
						<Typography
							style={{
								fontSize: "16px",
								fontWeight: "900",
							}}
						>
							Sign Up
						</Typography>
					</Button>


				</Box>

				<Box>
					<Typography
						style={{
							fontSize: "30px",
							fontWeight: "bold",
							margin: '20px 0px',
						}}
					>
						Recent Articles
					</Typography>
				</Box>

				<Box>

					<RecentArticleSwiper />
				</Box>

				<Box>
					<Typography
						style={{
							fontSize: "30px",
							fontWeight: "bold",
							margin: '20px 0px',
						}}
					>
						Older articles you might be interested in
					</Typography>
				</Box>

				<Box>
					<OlderArticleSwiper />
				</Box>

			</Container>




		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	newsLetterBody: {
		width: "100%",
		height: "100%",
	},
	container: {
		height: "100%",
		width: "80%",
		margin: "auto",
		// backgroundColor: "red",
	},
	letterPicContainer: {
		width: "300px",
		margin: "0"
	},
	letterContainer: {
		// width: "40%",
		// border: "2px solid red",
		display: "flex",
		margin: "5px",
		// backgroundColor: "#F6F6F6",
	},
	letterContentContainer: {
		margin: "0 10px",
		// border: "2px solid red",
		paddingTop: "0px",
	},
	letterTitle: {

		fontWeight: "bold",
		fontSize: "25px",
		verticalAlign: "top",
		margin: "0"
		// backgroundColor: "green"
	},
	letterContent: {
		margin: "0",
	},
	letterInfo: {
		display: "flex",
		margin: "0",
		color: "#3B79B1",
	},
}));

export default NewsLetterPage;