import firebase from "firebase/app";
// import { initializeApp } from 'firebase-admin/app';
import { getToxicity } from "./toxicity";
import { decodeUserUID } from "../utils/auth";
import { EmailHTML } from "../emailNotification/emailHTML";

// Notification setup
const handleNotification = (creatorEmail, content, postTitle, postText) => {
  console.log("handleNotification");
  window.Email.send({
    Host: 'smtp.elasticemail.com',
    Port: '2525',
    Username: 'pinocchio.hkust@gmail.com',
    Password: '1D9C3DAA3A226FE469EDFF23096145685198',
    To : `${creatorEmail}`,
    From : "pinocchio.hkust@gmail.com",
    Subject : "Notification from Pinocchio",
    Body : EmailHTML(content, postTitle, postText),
  });
}

export function uploadComment(userUID, storyID, comment) {


  // const admin = require('firebase-admin');

  var db = firebase.firestore();
  getToxicity(comment).then((score) => {
    if (score > 0.7) {
      db.collection("toxicComments")
        .add({
          userID: userUID,
          text: comment,
          toxicity: score,
          createdTime: firebase.firestore.Timestamp.now(), 
        })
        .catch((error) => console.log(error));
    } else {
      var story = db.collection("stories").doc(storyID);
      story
        .update({
          comments: firebase.firestore.FieldValue.arrayUnion(comment),
        })
        .catch((error) => console.log(error));

        

        var userID; 
        db.collection("stories")
                      .doc(storyID)
                      .get()
                      .then((doc) => {
                        if (doc.exists) {
                          console.log("Creator ID: ", doc.data().userID);
                          console.log("Creator Email: ", doc.data().creatorEmail);
                          console.log("Post title: ", doc.data().title);
                          console.log("Post content: ", doc.data().text);

                          var creatorEmail = doc.data().creatorEmail;
                          var commentContent = comment;
                          var postTitle = doc.data().title;
                          var postText = doc.data().text;

                          handleNotification(creatorEmail, commentContent, postTitle, postText);
                          // var decodedUserUID = decodeUserUID(doc.data().userID);
                          // console.log(decodedUserUID);

                          // this is for version 9 firebase
                          /* getAuth().getUser(doc.data().userID).then((record) => {
                            console.log(record.Email);
                          }) */

                          /* firebase.auth().getUser(doc.data().userID).then((record) => {
                            console.log(`Creator email: ${record.toJSON()}`);
                          }) */
                          
                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }
                      })
                      .catch((err) => console.log(err));
                      
        /* console.log(userID);
        var decodedUserUID = decodeUserUID(userID);
        console.log(decodedUserUID); */
        // handleNotification(userID, decodedUserUID);
    }
  });
}
