import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import {
  Button,
  Grid,
  useMediaQuery,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Typography,
  Box,
  InputBase,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Widgets } from "@material-ui/icons";

import { FetchOlderNewsletter } from "../../utils/fetchNewsletter";

import imgPokemon from "../../assets/pokemon.gif";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import "swiper/components/pagination/pagination.min.css"

import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

const newsletterData = [
  {
    title: "Am I not good enough?",
    content: "In our beloved university of stress and tension, the phrase “low self-esteem” is too often thrown around mindlessly as some self-deprecating humor to lighten the mood.",
    date: "30 April 2022",
    tags: ["self-esteem", "motivation"],
    coverImageURL: "@src/assets/pokemon.gif",
  },
  {
    title: "Am I not good enough?",
    content: "In our beloved university of stress and tension, the phrase “low self-esteem” is too often thrown around mindlessly as some self-deprecating humor to lighten the mood.",
    date: "30 April 2022",
    tags: ["self-esteem", "motivation"],
    coverImageURL: "src/assets/pokemon.gif",
  },
  {
    title: "Am I not good enough?",
    content: "In our beloved university of stress and tension, the phrase “low self-esteem” is too often thrown around mindlessly as some self-deprecating humor to lighten the mood.",
    date: "30 April 2022",
    tags: ["self-esteem", "motivation"],
    coverImageURL: "src/assets/pokemon.gif",
  },
]

// Custom pagination component
const CustomPagination = () => {
  return (
    <div className="custom-pagination">
      <span className="custom-pagination-item">1</span>
      <span className="custom-pagination-item">2</span>
      <span className="custom-pagination-item">3</span>
    </div>
  );
};

// Custom navigation buttons component
const CustomNavigation = () => {
  return (
    <>
      <button className="custom-prev-button">Prev</button>
      <button className="custom-next-button">Next</button>
    </>
  );
};

const OlderArticleSwiper = () => {

  const mdDown = useMediaQuery("(max-width:980px)");
  const style = useStyles();

  const history = useHistory();

  const [data, setData] = useState([]);

  useEffect(() => {
    FetchOlderNewsletter().then((doc) => {
      const result = doc.map((item) => item);
      console.log(result);
      setData(result);
    })
  }, []);

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={ mdDown ? 1 : 2}
      // centeredSlides
      onSlideChange={() => console.log("slide change")}
      onSwiper={swiper => console.log(swiper)}
      pagination={{ clickable: true, /* dynamicBullets: true  */}}
      // navigation={true}
      // pagination={{ el: '.custom-pagination' }}
      // navigation={{
      //   prevEl: '.custom-prev-button',
      //   nextEl: '.custom-next-button'
      // }}
      mousewheel={true}
      keyboard={true}
      loop={true}
      style={{
        // border: '2px solid blue',
        padding: ' 0 5px 40px 5px',
      }}
    >
      {
        data.map((item, index) => (
          <SwiperSlide style={{ padding: '0px' }}>
            <Box
              style={{
                padding: '30px',
                backgroundColor: '#FFEBEB',
                // aspectRatio: '16/9',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                borderRadius: '20px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
              }}
            >
              <Box
                style={{
                  width: '100%',
                }}
              >
                <img src={imgPokemon} width="100%" style={{ borderRadius: '20px' }}></img>
              </Box>

              <Box
                style={{
                  width: '100%',
                  // border: '2px solid red',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                    }}
                  >
                    {item.data().title}
                  </Typography>

                  <Box
                    style={{
                      display: 'flex',
                      gap: '10px',
                      flexWrap: 'wrap',
                      margin: '20px 0',
                    }}
                  >
                    {
                      item.data().tags.map((tag, index) => (
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontWeight: 'light',
                            backgroundColor: '#CCCCCC',
                            borderRadius: '30px',
                            padding: '0 10px',
                            width: 'fit-content',
                            // marginRight: '10px',
                          }}
                        >
                          {tag}
                        </Typography>
                      ))
                    }
                  </Box>

                  <Typography
                    style={{
                      fontSize: '16px',
                      fontWeight: 'light',
                    }}
                  >
                    {item.data().content}
                  </Typography>

                </Box>

                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    className={style.readMoreBtn}
                  >
                    <Typography>
                      Read More {'>>'}
                    </Typography>
                  </Button>
                </Box>


              </Box>
            </Box>
          </SwiperSlide>

        ))
      }

    </Swiper>
  )
}

const useStyles = makeStyles((theme) => ({
  readMoreBtn: {
    textTransform: 'none',
    transition: 'all 0.2s ease-in-out',
    "&:hover": {
      backgroundColor: "transparent",
      color: "#3C79B0",
      fontWeight: 'bold', 
    }
  }
}));

export default OlderArticleSwiper;