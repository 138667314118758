import firebase from "firebase/app";

const FetchNewsletter = async () => {

  var db = firebase.firestore();

  return await db
    .collection("newsletter")
    .get()
    .then(async querySnapshot => {
      // querySnapshot.forEach((doc) => {
      //   // doc.data() is never undefined for query doc snapshots
      //   // console.log(doc.id, " => ", doc.data());
      // });

      if (!querySnapshot.empty) {
        return await querySnapshot.docs.map((doc) => doc.data());
      } else {
        console.log("ERROR_NO_NEWSLETTER");
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

const FetchRecentNewsletter = async () => {

  var db = firebase.firestore();

  return await db
    .collection("newsletter")
    .orderBy("date", 'desc')
    .limit(3)
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs;
    });
}

const FetchOlderNewsletter = async () => {
  
    var db = firebase.firestore();

    var cutOffDoc = await db
      .collection("newsletter")
      .orderBy("date", 'desc')
      .limit(1)
      .get();
    
    
  
    return await db
      .collection("newsletter")
      .orderBy("date", 'desc')
      .get()
      .then((querySnapshot) => {
        console.log('querySnapshot.docs', querySnapshot.docs);
        return querySnapshot.docs;
      });
}

const FetchNewsletterByID = async (id) => {

  var db = firebase.firestore();

  return await db
    .collection("newsletter")
    .doc(id)
    .get()
    .then((doc) => {
      return doc.data();
    });
}

export { FetchNewsletter, FetchRecentNewsletter, FetchOlderNewsletter, FetchNewsletterByID };