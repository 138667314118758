import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import {
  Button,
  Grid,
  useMediaQuery,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Typography,
  Box,
  InputBase,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { Button } from "@mui/material";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { FetchNewsletterByID } from "../../utils/fetchNewsletter";

// Icons
import { Widgets } from "@material-ui/icons";
import { FaShare } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";

// Custom Components
import NavigationBar from "../../components/NavigationBar";

const NewsletterContentPage = () => {

  const history = useHistory();
  const { id } = useParams();

  const [content, setContent] = useState('');
  const [writer, setWriter] = useState('');

  useEffect(() => {
    FetchNewsletterByID(id)
      .then((res) => {
        setContent(res.fullHTML);
        setWriter(res.writer);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  // const [editorValue, setEditorValue] = useState();

  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <NavigationBar />

      <Container maxWidth="lg">
        <Box
          style={{
            // aspectRatio: "9/16",
            backgroundColor: '#FFD8D7',
            borderRadius: "50px",
            padding: "50px",
            margin: '30px 0',
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'flex-start',
            padding: '0 50px',
          }}
        >
          <Typography
            style={{
              // fontWeight: "bold",
            }}
          >
            Written by {writer}
          </Typography>

          <Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              <Typography
                style={{
                  // fontWeight: "bold",
                  fontSize: '18px',
                }}
              >
                Share this post
              </Typography>
              <FaShare
                style={{
                  fontSize: '18px',
                  cursor: 'pointer',
                }}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                gap: '16px',
              }}
            >
              <BsInstagram style={{ fontSize: '20px', cursor: 'pointer' }} />
              <FaFacebook style={{ fontSize: '20px', cursor: 'pointer' }} />
              <BsTwitter style={{ fontSize: '20px', cursor: 'pointer' }} />
              <FaLinkedinIn style={{ fontSize: '20px', cursor: 'pointer' }} />
            </Box>
          </Box>

        </Box>

        <Typography
          style={{
            width: '100%',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '24px',
            margin: '30px 0',
          }}
        >
          Like what you saw? <br />
          Subscribe to our newsletter to get the first news and update!
        </Typography>

        {/* <br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
      </Container>

    </Box>
  )
}

export default NewsletterContentPage;