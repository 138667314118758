export const EmailHTML = (content, postTitle, postText) => {
    return `
      <h1 style="text-align: center;">Your post got a new comment</h1>
      <p>Your Post Title: </p>
      <p>${postTitle}</p>
      <p>Your Post Content: </p>
      <p>${postText}</p>
      <hr>
      <p>New Comment: </p>
      <p>${content}</p>
      <hr>
      <p>Visit our forum page <a href="https://pinocchio.sight.ust.hk/">https://pinocchio.sight.ust.hk/</a></p>
    `;
  }